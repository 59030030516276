body {
  color: $color-text;
  font-family: $font-base;
  font-size: $font-size-base;
  line-height: 125%;
}

h1, h2, h3, h4, h5 {
  font-family: $font-heading;
  line-height: 135%;
  margin: 0.75em 0;

  a, em, i {
    font-family: $font-heading;
  }
}

h1 {
  font-size: $font-size-xlarge;
  font-weight: $font-weight-bold;

  @media #{$medium-and-down} {
    font-size: $font-size-large;
  }
}

h2 {
  font-size: $font-size-large;
  font-weight: $font-weight-normal;

  @media #{$medium-and-down} {
    font-size: $font-size-medium;
  }
}

h3 {
  font-size: $font-size-medium;
  font-weight: $font-weight-semibold;

  @media #{$medium-and-down} {
    font-size: $font-size-semimedium;
  }
}

h4 {
  font-size: $font-size-normal;
  font-weight: $font-weight-semibold;
}

p {
  font-size: $font-size-normal;
  margin: 1em 0;
}

a {
  color: $color-dark-blue;
  font-weight: $font-weight-semibold;
  text-decoration: none;

  &:hover {
    color: darken($color-dark-blue, 10%);
  }

  i, em {
    color: $color-dark-blue;
  }
}

blockquote {
  border-left: 5px solid $color-red;
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  line-height: 130%;
  margin: 0;
  padding-left: 1em;
  word-break: normal;
}

code {
  white-space : pre-wrap !important;
}

.text-align--center {
  text-align: center;
}

.no-bottom-margin {
  margin: 0.75em 0 0 0;
}

@mixin badge($color, $text: $color-white) {
  background-color: $color;
  border-radius: $badge-border-radius;
  color: $text;
  font-size: $font-size-small;
  padding: 0.5em;
  text-decoration: none;
  word-break: normal;
}

@mixin flex-row-wrap($column-on-medium: true) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @if $column-on-medium {
    @media #{$medium-and-down} {
      flex-direction: column;
    }
  }
}

@mixin emphasis-block {
  border-top: 2px solid $color-electric-blue;
  border-bottom: 2px solid $color-electric-blue;
  font-weight: $font-weight-semibold;
  padding: 1em 0.5em;
}

@mixin container-settings {
  display: block;
  margin: 0 auto;
  max-width: $max-width;
  overflow: auto;
  padding: 0 1em;
  width: 100%;
}


@mixin electric-link {
  a {
    color: $color-electric-blue;
    text-decoration: none;

    &:hover {
      color: darken($color-electric-blue, 10%);
      text-decoration: underline;
    }
  }
}

.home__about {
  margin: 4em auto 1em;
  padding: 1em 0;

  h2 {
    margin: 0 0 1em 0;

    @media #{$medium-and-down} {
      margin: 1em 0;
    }
  }

  img {
    border-radius: 50%;
    height: initial !important;

    @media #{$medium-and-down} {
      height: 300px !important;
    }
  }
}

.home__row {
  display: flex;
  flex-wrap: wrap;

  .home__artwork {
    background-color: $color-dark-blue;
    padding: 3em 0 1em 0;
    width: 60%;

    h2 {
      color: $color-white;
      margin: 0;
      text-align: center;
    }

    .splide {
      margin: 0 auto;
      max-width: $max-width * 0.8;
    }

    @media #{$large-and-down} {
      width: 100%;
    }
  }

  .home__spine {
    background-image: url("/assets/images/art/mechanics/The-Spine-representation.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    width: 40%;

    @media #{$large-and-down} {
      height: 400px;
      width: 100%;
    }
  }
}

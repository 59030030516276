*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: $color-white;
  padding: 0;
  margin: 0;
  width: 100%;
}

.container {
  @include container-settings;
}

.pagination {
  @include flex-row-wrap(true);
  justify-content: space-between;
  padding: 0;
  margin: 2em 0 0 0;

  a {
    background: $color-electric-blue;
    border-radius: 2px;
    color: $color-black;
    font-size: $font-size-normal;
    font-weight: $font-weight-bold;
    margin: 0.5em 0;
    padding: 1em 2em;
    text-decoration: none;

    &:hover {
      background: lighten($color-electric-blue, 10%);
      text-decoration: none;
    }

    @media #{$medium-and-down} {
      width: 100%;
    }
  }
}

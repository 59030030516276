.page__header {
  @include container-settings;
  padding-bottom: 1em;
}

.page__wrapper--gray-blue {
  @include electric-link;
  background-color: $color-gray-blue;
  color: $color-white;
  padding: 2em 0;
  margin: 0 auto 0;
}

.page__hero {
  @include electric-link;
  background-color: $color-gray-blue;
  color: $color-white;
  padding: 1em 0 2em 0;

  .page__title {
    h1 {
      color: $color-white;
      font-size: $font-size-xlarge * 1.2;
      margin: 0 0 1em 0;

      @media #{$medium-and-down} {
        font-size: $font-size-large;
      }
    }
  }
}

summary {
  font-family: $font-heading;
  font-size: $font-size-semimedium;
  font-weight: $font-weight-bold;
  margin: 0.5em 0;

  em {
    font-family: $font-heading;
  }
}

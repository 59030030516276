[class*="button--"] {
  border-radius: 2px;
  color: $color-white;
  display: block;
  font-size: $font-size-medium;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none !important;
}

.button--blue {
  background: $color-dark-blue;

  &:hover {
    background: darken($color-dark-blue, 10%);
    color: $color-white;
  }
}

.button--outline {
  border: 2px solid $color-dark-blue;
  background: $color-white;
  color: $color-text;

  &:hover {
    background: rgba($color-dark-blue, 0.1);
  }
}

.button--electric {
  background: $color-electric-blue;
  color: $color-black;

  &:hover {
    background: lighten($color-electric-blue, 10%);
    color: $color-black;
  }
}

.row {
  @include flex-row-wrap;

  .col-6 {
    flex: calc(50% - 1em);
    max-width: calc(50% - 1em);
    margin: 0.5em;

    @media #{$medium-and-down} {
      margin: 1em 0;
      flex: 100%;
      max-width: 100%;
    }
  }
}


.splide {
  margin: 1em 0;
}

.splide__slide {
  opacity: 0.66;

  @media #{$medium-and-down} {
    figure {
      max-width: 400px;
    }
  }
}

.splide__slide.is-active {
  opacity: 1;
}

.splide__track--nav>.splide__list>.splide__slide.is-active {
  border: 0 !important;
}

.splide--thumbnail {
  figcaption {
    font-size: $font-size-xsmall;
    width: 100%;
  }
}

.story-entry {
  border-bottom: 1px solid $color-gray-blue;
  margin: 0;
  padding: 0;
  width: 100%;

  h3 {
    font-size: $font-size-normal;
  }
}

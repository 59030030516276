img {
  display: block;
  max-height: $max-image-height;
  margin: 0 auto;
  max-width: 100%;
  object-fit: contain;
  width: auto;

  &.unset-width {
    height: inherit;
    margin: 0;
    width: inherit;
  }

  &.small {
    max-height: $max-image-height-sm;
  }
}

figure {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto 1em;
  width: 100%;

  figcaption {
    background-color: $color-black;
    color: $color-white;
    margin: 0.4em auto 0;
    max-width: 100%;
    width: -webkit-fit-content;
    padding: 0.5em 1em;
    text-align: center;

    a {
      color: $color-white;
      text-decoration: underline;

      &:hover {
        color: $color-white;
      }
    }
  }
}

.image-gallery {
  @include flex-row-wrap(false);
  margin-bottom: 1em;

  figure {
    display: block;
    flex: 30%;
    margin: 0.5em;
    max-width: 30%;

    @media #{$large-and-down} {
      flex: calc(50% - 0.5em);
      margin: 0.25em;
      max-width: calc(50% - 0.5em);
    }
  }

  &.quarters {
    figure {
      flex: calc(25% - 1em);
      max-width: calc(25% - 1em);

      @media #{$large-and-down} {
        flex: calc(50% - 0.5em);
        margin: 0.25em;
        max-width: calc(50% - 0.5em);
      }
    }
  }

  &.half {
    figure {
      flex: calc(50% - 1em);
      max-width: calc(50% - 1em);

      @media #{$large-and-down} {
        flex: calc(50% - 0.5em);
        margin: 0.25em;
        max-width: calc(50% - 0.5em);
      }
    }
  }
}

.image-with-text {
  @include flex-row-wrap;

  .image-with-text__image {
    flex: 1;
    padding: 0 1em;

    img {
      height: $max-image-height-sm;
    }
  }

  .image-with-text__text {
    flex: 3;
    padding: 0 1em;
  }

  @media #{$medium-and-down} {
    .image-with-text__image,
    .image-with-text__text {
      padding: 0;
    }
  }
}

.post {
  .post__featured-img {
    margin: 1em auto;
    max-width: 700px;

    @media #{$medium-and-down} {
      max-width: 100%;
    }
  }

  .post__subtitle {
    text-align: center;
  }

  .post__content-divider {
    border: none;
    border-top: 2px solid $color-electric-blue;
    margin: 2em 0;
  }

  .post__spoilers {
    background-color: $color-red;
    color: $color-white;
    padding: 1em;
    text-align: center;
  }

  .post__meta {
    @include flex-row-wrap(false);

    .post__date,
    .post__categories,
    .post__tags {
      @include flex-row-wrap(false);
      margin: 0.25em 1em 0.25em 0;

      a, span {
        margin-right: 0.5em;

        @media #{$medium-and-down} {
          margin: 0.25em 0.5em 0.25em 0;
        }
      }
    }

    .post__date span {
      @include badge($color-gray-blue);
    }

    .post__categories a {
      @include badge($color-dark-blue);

      &:hover {
        background-color: darken($color-dark-blue, 10%);
      }
    }

    .post__tags a {
      @include badge($color-dark-blue);

      &:hover {
        background-color: darken($color-dark-blue, 10%);
      }
    }
  }
}

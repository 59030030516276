.poem {
  h1 {
    margin-bottom: 1.25em;
    text-align: center;
  }

  .poem__text {
    margin-bottom: 3em;
    line-height: 0.9em;
    text-align: center;

    .divider {
      border-top: 1px dotted $color-electric-blue;
      margin: 2em auto;
      max-width: 500px;
    }

    .poem__row {
      @include flex-row-wrap;

      .poem__col {
        flex: 50%;

        @media #{$medium-and-down} {
          margin-top: 2em;
          flex: 100%;
        }
      }
    }
  }

  .poetry__block {
    font-size: $font-size-normal;
    white-space: pre-line;
  }

  .poem__meta {
    @include flex-row-wrap;
  }
}

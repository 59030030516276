// Colors

$color-black: #000;
$color-gray-blue: #1b2631;
$color-white: #fff;

$color-text: #000;

$color-red: #950f0f;
$color-electric-blue: #00eac3;
$color-dark-blue: #064972;

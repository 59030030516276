.quote {
  margin: 1em 0 0 0;

  blockquote {
    border-left: 5px solid $color-red;
    font-style: normal;
  }

  .quote__attr {
    font-size: $font-size-normal;
    margin: 0.5em 1em;

    &:before {
      content: "-";
    }
  }
}

.page__wrapper--gray-blue .quote blockquote {
  border-left-color: $color-electric-blue;
}

.series {
  h2 {
    margin-bottom: 1em;
  }

  .series__set {
    @include flex-row-wrap(false);
    justify-content: space-between;
    align-items: flex-end;
    margin: 2em 0;

    .series__set-book {
      display: flex;
      flex-direction: column;
      flex: calc(22% - 0.6em);
      justify-content: space-between;
      margin: 0 0.3em;

      h3 {
        font-size: $font-size-small !important;
        margin: 1em 0 0 0;
        text-align: center;
      }

      .series__set-book--order {
        font-size: $font-size-xsmall !important;
      }

      a {
        color: $color-black;
      }

      .series__set-book--cover {
        flex-shrink: 0;
        margin-top: 1em;
      }

      @media #{$medium-and-down} {
        flex: calc(50% - 0.5em);
        margin: 0.5em 0.25em;
      }
    }
  }
}

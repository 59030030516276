@mixin book-section {
  margin-top: 2em;
}

.book {
  .book__subtitle {
    font-size: $font-size-medium;
    margin-bottom: 1em;
  }

  .book__cover {
    margin-bottom: 0.5em;
    width: 100%;

    &--tall {
      margin: 0.25em auto;
      max-width: 400px;
    }

    &--hero {
      img {
        max-height: 1000px;
      }
    }
  }

  .book__content {
    @include flex-row-wrap;
    margin-bottom: 2em;

    .book__sidebar {
      flex: 2;
    }

    .book__blurb {
      flex: 5;
      padding: 0 1em;
    }

    @media #{$medium-and-down} {
      .book__sidebar,
      .book__sidebar {
        flex: 100%;
        padding: 0;
        width: 100%;
      }
    }
  }

  .book__get-copy {
    display: flex;
    flex-direction: column;
    align-items: center;

    [class*="button--"] {
      font-size: $font-size-normal;
      padding: 0.5em 0.75em;
      margin: 0.25em 0;
      width: 100%;

      @media #{$medium-and-down} {
        max-width: 80%;
      }
    }
  }

  .book__get-copy-slim-wrapper {
    .book__get-copy {
      align-items: flex-start;

      [class*="button--"] {
        width: auto;

        @media #{$medium-and-down} {
          width: auto;
        }
      }
    }
  }

  .book__posts {
    font-size: $font-size-semimedium;
    margin-top: 1em;
  }

  .book__scenes {
    padding: 1em 0 0;
  }

  .book__meta {
    margin-top: 2em;
    padding: 1.5em 0 0;
    text-align: right;

    span {
      font-size: $font-size-small;
      font-style: italic;
      font-weight: $font-weight-bold;
    }
  }

  .book__acknowledgements {
    margin-top: 1em;

    .book__acknowledgement {
      font-weight: $font-weight-bold;

      &::before {
        content: '★';
        font-size: $font-size-xsmall;
        margin: 0 0.5em;
      }
    }
  }

  &--short-story {
    .book__cover {
      margin: 0.25em auto;
      padding: 0;
    }

    .book__text {
      border-top: 2px solid $color-electric-blue;
      border-bottom: 2px solid $color-electric-blue;
      margin: 1em auto;
      padding-top: 0.5em;
    }
  }

  &--scanned-story {
    .book__cover {
      margin: 0 auto 2em;
    }

    .book__text {
      border-top: none;
      margin: 0 auto;
      max-width: 100%;
      padding: 0;
    }
  }

  hr {
    border: none;
    border-top: 2px solid $color-electric-blue;
    margin: 1.5em 0;
  }

  .pagination {
    margin: 1em 0;
    width: 100%;

    a {
      background: $color-white;
      color: $color-dark-blue;
      font-weight: $font-weight-semibold;
      padding: 0.25em 0.5em;

      &:hover {
        background: $color-white;
        text-decoration: underline;
      }
    }
  }
}


.story__grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .card {
    flex: 48%;
    max-width: 48%;
    margin: 0.5em 1%;

    .card__header {
      padding: 0 1em 0;

      h2, h3 {
        text-align: left;
      }
    }
  }
}

.blog__container {
  @include flex-row-wrap(true);

  .blog__sidebar {
    flex: 3;
    padding-left: 2em;

    @media #{$medium-and-down} {
      @include flex-row-wrap(false);
      padding-left: 0;
      margin-top: 1em;
      padding-top: 1em;
      border-top: 1px solid $color-dark-blue;

      .blog__tag-content {
        flex: 50%;
      }

      .blog__category-content {
        flex: 50%;
      }
    }

    h4 {
      display: block;
      font-size: 1.1em;
      font-weight: $font-weight-bold;
      margin: 0.5em 0;
    }

    .blog__tags {
      @include flex-row-wrap(false);
      width: 100%;

      a {
        background-color: $color-dark-blue;
        border-radius: 3px;
        color: $color-white;
        font-size: $font-size-small;
        font-weight: $font-weight-semibold;
        margin: 0.2em;
        padding: 0.25em 0.6em;

        &:hover {
          background-color: darken($color-dark-blue, 10%);
        }

        &.selected {
          background-color: $color-gray-blue;

          &::after {
            content: '<';
            font-size: $font-size-normal;
            margin: 0 0.5em;
          }

          &:hover {
            background-color: $color-gray-blue;
          }
        }
      }
    }

    .blog__categories {
      display: flex;
      flex-direction: column;

      @media #{$medium-and-down} {
        flex: 50%;
        margin: 1em 0;
      }

      a {
        margin: 0.1em 0;

        &.selected {
          color: $color-gray-blue;

          &::after {
            content: '<';
            font-size: $font-size-normal;
            margin: 0 0.5em;
          }
        }
      }
    }
  }

  .blog__content {
    flex: 7;
  }
}

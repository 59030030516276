ul {
  font-size: $font-size-normal;
}

.toc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    display: block;
    counter-increment: link;
    flex: 50%;

    &::before {
      content: counter(link) ". ";
    }
  }
}

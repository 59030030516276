// Exo 2

/* exo-2-regular - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('../assets/fonts/exo2/exo-2-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/exo2/exo-2-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-2-700 - latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../assets/fonts/exo2/exo-2-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../assets/fonts/exo2/exo-2-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.card {
  border-color: $color-dark-blue;
  position: relative;
  border-radius: 6px;
  border-style: solid;
  border-width: 4px;
  font-weight: inherit;
  padding: 1em 0 0.5em;

  &::before {
    background-color: $color-dark-blue;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.8s;
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    text-decoration: none;

    &::before {
      opacity: 0.15;
    }
  }

  .card__date {
    @include badge($color-dark-blue, $color-white);
  }

  a {
    color: $color-black;
    text-decoration: none;
  }

  .card__body {
    color: $color-text;
    padding: 0 1em;
  }

  .card__header {
    padding: 0 1em 1em;

    h2, h3 {
      color: $color-text;
      font-size: $font-size-semimedium;
      font-weight: $font-weight-semibold;
      margin: 0;
      text-align: center;
    }
  }

  .card__footer {
    @include flex-row-wrap(false);
    padding: 0 1em;

    .card__date,
    .card__categories {
      @include flex-row-wrap(false);
      margin: 0.25em 1em 0.25em 0;

      span, a {
        margin-right: 0.5em;
      }
    }

    .card__categories span {
      @include badge($color-dark-blue);

      &:hover {
        background-color: darken($color-dark-blue, 10%);
      }
    }
  }

  .card__thumb {
    padding: 0;

    img {
      margin: 0 auto;
      height: 200px;
    }
  }
}

.card__grid {
  @include flex-row-wrap;
  justify-content: space-between;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 49%;
    margin: 0.5em 0;
    max-width: 49%;

    @media #{$medium-and-down} {
      flex: 100%;
      max-width: 100%;
    }
  }
}

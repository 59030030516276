@import 'fonts/exo';

// Font

$font-base: Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif;
$font-heading: 'Exo 2', sans-serif;

$font-size-base: 19px;

$font-size-xlarge: 2.25em;
$font-size-large: 2em;
$font-size-medium: 1.5em;
$font-size-semimedium: 1.3em;
$font-size-normal: 1em;
$font-size-small: 0.85em;
$font-size-xsmall: 0.75em;

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-normal: 400;

@mixin nav-link {
  border-bottom: 3px solid $color-gray-blue;
  display: block;
  color: $color-white;
  font-family: $font-base;
  font-size: $font-size-normal;
  font-weight: $font-weight-semibold;
  padding: 0.25em;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    border-bottom: 3px solid $color-electric-blue;
    color: $color-electric-blue;
  }

  @media #{$medium-and-down} {
    display: none;
  }
}

.nav {
  @include flex-row-wrap(false);
  align-items: center;
  background: $color-gray-blue;
  justify-content: space-around;
  padding: 1em;
  position: relative;

  .nav__site-title {
    padding: 0;

    img {
      display: block;
      height: 100%;
      margin: 0 auto;
      max-width: 250px;
      width: 100%;
    }

    @media #{$large-and-down} {
      img {
        height: 100%;
        margin: 0 auto 1em auto;
        max-width: 50%;
        width: 100%;
      }
    }
  }

  span.nav-tab {
    a {
      @include nav-link;

      @media #{$medium-and-down} {
        display: none;
      }
    }

    &--mobile {
      a {
        @media #{$medium-and-down} {
          display: block;
        }
      }
    }
  }

  a.nav-tab {
    @include nav-link;

    @media #{$medium-and-down} {
      display: none;
    }

    &--mobile {
      @media #{$medium-and-down} {
        display: block;
      }
    }
  }

  .nav-tab__dropdown {
    background: $color-gray-blue;
    display: none;

    &--show {
      display: block;
      position: absolute;
      top: 65%;
      z-index: 100;

      @media #{$medium-and-down} {
        top: 75%;
      }
    }

    a {
      border-left: 3px solid $color-gray-blue;
      border-bottom: none !important;
      font-size: $font-size-small !important;
      padding: 0.75em !important;

      &:hover {
        border-left: 3px solid $color-electric-blue;
        border-bottom: none !important;
      }
    }
  }
}

.breadcrumb {
  margin-top: 1em;

  a {
    font-size: $font-size-small;

    &::before {
      content: '<< ';
      font-size: $font-size-small;
      margin: 0 0.25em;
    }
  }
}

footer {
  background: $color-gray-blue;
  margin-top: 2em;
  padding: 2em 4em;

  @media #{$medium-and-down} {
    padding: 1em;
  }

  a {
    color: $color-white;
    font-weight: $font-weight-normal;

    &:hover {
      color: $color-electric-blue;
      text-decoration: none;
    }
  }

  .footer__copyright {
    color: $color-white;
    margin-top: 3em;
    text-align: center;

    @media #{$medium-and-down} {
      margin: 2em 0;
    }
  }

  .footer__row {
    @include flex-row-wrap(false);
    justify-content: space-between;
    align-items: center;
  }

  .footer__core {
    .footer__logo {
      display: block;

      img {
        display: block;
        height: 100%;
        margin: 0 auto;
        max-width: 250px;
        width: 100%;
      }

      @media #{$large-and-down} {
        img {
          height: 100%;
          max-width: 50%;
          width: 100%;
        }
      }
    }

    .link__cta {
      background: $color-electric-blue;
      border-radius: 2px;
      color: $color-black;
      display: block;
      margin: 1em auto;
      padding: 0.5em 1em;
      text-decoration: none !important;
      text-align: center;
      width: fit-content;

      &:hover {
        background: lighten($color-electric-blue, 10%);
        color: $color-black;
      }
    }
  }

  .footer__nav {
    display: flex;
    flex-direction: column;

    a {
      margin: 0.1em 0;
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 1.5em;
  width: 100%;

  th, td {
    text-align: left;
    border: 1px solid;
    padding: 1%;
  }
}
